:root {
  --white: #fff;
  --black: #000;
  --dk-blue: #337ab7;
  --color-gray-0: #fbfbfb;
  --color-gray-1: #f4f5f7;
  --bs-font-sans-serif: "Helvetica Neue", "Segoe UI", helvetica, verdana,
    sans-serif !important;
  --web-bg: #f3f3f3;
}

.table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: transparent;
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--black);
  --bs-table-striped-bg: var(--color-gray-0);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.bg-white {
  background: var(--white);
}

.btn-primary {
  --bs-btn-bg: var(--dk-blue);
}

.main-header {
  background: var(--web-bg);
  border-bottom: 1px solid #eee;
  padding: 0;
  align-items: center;
  width: 100%;
  height: 40px;
}

.form-control,
.form-select {
  line-height: 28px;
  height: 28px;
  padding: 0px 5px;
  font-size: 13px;
  border-radius: 3px;
  font-weight: 500;
  color: #333;
}

.form-control::placeholder,
.form-select::placeholder {
  color: #ccc;
  font-weight: 400;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none;
}

.header-select {
  line-height: 26px;
  height: 26px;
  padding: 0px 5px;
  margin: 8px 0;
  font-size: 12px;
}

.menu-sidebar {
  background: var(--dk-blue);
  min-width: 210px;
  max-width: 210px;
  margin-left: -210px;
  transition: all 0.5s ease-in-out;
  position: relative;
  z-index: 1000;
}

.sidebar-logo {
  width: 100%;
  text-align: center;
  height: 50px;
  padding: 5px;
  margin-top: 5px;
}

.sidebar-logo img {
  max-height: 40px;
  max-width: 100%;
  cursor: pointer;
}

.sidebar-visible .menu-sidebar {
  margin-left: 0;
}

.app-main {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.sidebar-ctrl-i {
  /* font-size: 18px;
      color: var(--dk-blue);
      cursor: pointer; */
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  background: var(--dk-blue);
  width: 32px;
  height: 32px;
  color: #fff;
  left: -4px;
  top: 3px;
  padding: 8px;
}

.sidebar-visible .sidebar-ctrl-i {
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  background: var(--web-bg);
  width: 32px;
  height: 32px;
  color: var(--dk-blue);
  left: -8px;
  top: 3px;
  padding: 8px;
  z-index: 1;
}

.main-content {
  background: var(--web-bg);
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.sidebar-visible .main-content {
  width: calc(100% - 210px);
}

.min-w-240 {
  min-width: 240px !important;
}

.w-240 {
  width: 240px;
}

.sidebar-links li {
  outline: none;
  transition: all 0.1s ease;
  border: 2px solid transparent !important;
  border-radius: var(--border-radius-md);
  padding: 0 10px;
  height: 38px;
  line-height: 38px;
}

.sidebar-links li a {
  color: var(--white);
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  display: block;
  padding-left: 3px;
}

.parent-sidemenu .dropdown-menu .dropdown-item {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.sidebar-links li.active {
  /* color: var(--white);
      background-color: var(--dk-blue); */
  color: var(--dk-blue);
  background-color: rgb(0 52 88 / 10%);
  font-weight: 700;
}

.sidebar-item-active {
  color: var(--dk-blue) !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  font-weight: 700;
}

.gpnt-sidemnu:hover {
  color: var(--dk-blue) !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  font-weight: 700;
}

.tbl-datagrid thead th,
.tbl-datagrid tr td {
  padding-left: 5px;
}

.btn-icon {
  position: relative;
  bottom: 2px;
}

.btn-add {
  font-weight: 500;
}

.right-0 {
  right: 0;
}

.header-tab-menus .item {
  padding: 5px 10px;
  font-weight: 500;
  color: #fff;
  font-size: 10px;
  cursor: pointer;
  text-decoration: none;
  margin: 0px 0px;
  border-radius: 0;
  text-transform: uppercase;
  letter-spacing: 0.3px;
}

.header-tab-menus .item.active {
  color: var(--dk-blue);
  background-color: #fcfcfc;
  border: 1px solid transparent;
  border-radius: 0;
  border-top: 2px solid var(--dk-blue);
  font-weight: 700;
}

.modal-title {
  color: var(--dk-blue);
}

.card-header {
  background-color: rgb(20 110 190 / 4%);
  border-radius: 0 !important;
}

.card-footer {
  border-radius: 0 !important;
}

.page-header {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 5px;
  padding-top: 8px;
  height: 28px;
  font-size: 14px;
  margin-bottom: 0;
}

.gpnt-title {
  position: relative;
  top: 4px;
  font-size: 16px;
  font-weight: 500;
}

.btn-gpnt {
  padding: 5px 10px;
  font-size: 12px;
}

.btn-gpnt .gpnt-icon {
  position: relative;
  bottom: 2px;
}

.login-page {
  /* background: var(--color-gray-0); */
  background: var(--dk-blue);
}

.login-logo {
  width: 100%;
  padding: 5px;
}

.login-logo img {
  max-height: 60px;
}

.login-card-header {
  padding: 10px;
  font-size: 16px;
}

.btn-quick-add {
  width: 90px;
}

.header-links {
  color: var(--dk-blue) !important;
  cursor: pointer;
}

.user-profile .dropdown-menu {
  top: 45px;
  left: auto;
  margin-top: var(--bs-dropdown-spacer);
  max-width: 270px;
  border-radius: 0;
  right: 8px;
}

.user-profile .dropdown-toggle::after {
  display: none;
}

.header-icon {
  font-size: 24px;
  padding: 2px;
}

.parent-sidemenu {
  position: relative;
}

.parent-sidemenu .dropdown-toggle {
  width: 100%;
  color: var(--white);
  text-decoration: none;
  font-size: 12px;
  display: block;
  margin: 0;
  background: transparent;
  border: 0;
  text-align: left;
  font-weight: 500;
  padding: 0px 4px 0px !important;
  height: 38px;
  line-height: 38px;
}

.parent-sidemenu .dropdown-toggle::after {
  position: absolute;
  top: 14px;
  right: 8px;
  font-size: 14px;
}

.parent-sidemenu .dropdown-menu.show {
  display: block;
  top: -40px !important;
  border-radius: 0;
  background: var(--dk-blue);
  padding: 0;
  position: relative !important;
  left: 0px !important;
}

.parent-sidemenu .dropdown-menu .dropdown-item {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 38px;
  padding: 0;
}

.parent-sidemenu.active {
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.parent-sidemenu .dropdown-menu .dropdown-item:focus,
.parent-sidemenu .dropdown-menu .dropdown-item:hover {
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

/* .parent-sidemenu:hover .dropdown-menu{
      display:block!important
    } */
.menu-sidebar .list-group-item {
  background: transparent;
  border: 0;
}

.gpnt-form-label {
  font-size: 0.8rem;
  text-align: left;
  text-transform: capitalize;
  font-weight: 500;
  display: flex;
  /* align-items: center; */
}

.gpnt-form-control {
  font-size: 0.8rem;
  font-weight: 500;
}

.gpnt-sep {
  margin: 8px 0 !important;
  border: 1px solid #eee;
}

.gpnt-modal-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  font-size: 1rem;
  height: 38px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  padding-left: 12px;
}

.gpnt-modal-header .btn-close {
  font-size: 12px;
}

.gpnt-modal-title {
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0;
  padding-left: 0px;
}

.gpnt-modal-close {
  font-size: 0.9rem;
}

.logo-text {
  font-size: 22px;
  margin: auto;
  text-align: left;
  display: block;
  padding: 10px;
}

.popover {
  padding: 0;
  border: 0;
}

.popover-body {
  padding: 0;
  min-width: 250px;
  box-shadow: 1px 2px 1px #eee;
}

.figure {
  width: 35px;
  height: 35px;
  border: 1px solid #eee;
  border-radius: 100%;
  background-color: var(--dk-blue);
  color: #fff;
  line-height: 1.8;
  margin: 0;
}

.figure-caption {
  color: #fff;
}

.header-tab-menus {
  display: flex;
  align-items: center;
  list-style: none;
  max-width: 100%;
  scroll-behavior: smooth;
  background: var(--dk-blue);
  color: #fff;
  overflow: auto;
  white-space: nowrap;
  border-bottom: 1px solid #cccfd1;
  box-shadow: 1px 2px 2px #efefef;
}

.header-tab-menus::-webkit-scrollbar {
  background: transparent;
  /* make scrollbar transparent */
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

.tabmenu-prev,
.tabmenu-next {
  float: left;
  transform: none !important;
  height: 28px;
  line-height: 14px;
  width: 35px;
  background: #eee;
  color: var(--dk-blue);
  border: 0;
  text-align: center;
  margin: auto;
  font-size: 22px;
}

.overlow-tabmenu {
  left: 0px;
  width: calc(100% - 35px);
  float: left;
}

.tabmenu-prev + .header-tab-menus {
  left: 35px;
  width: calc(100% - 70px);
  float: left;
}

.tabmenu-next {
  display: none;
}

.overlow-tabmenu + .tabmenu-next {
  display: block;
}

.gpnt-form-group {
  /* margin-bottom: 5px; */
  padding-right: 0;
}

.sync-newspaper-btn {
  color: white;
  background-color: #337ab7;
  padding: 3px 6px;
  font-size: 12px;
  border: 2px black solid;
  margin-top: 1px;
}
.gpnt-form-checkbox .form-check-input {
  width: 1.3em !important;
  height: 1.3em !important;
  margin-top: 0.15em !important;
  margin-right: 5px !important;
}

.canvas-footer {
  display: flex;
  justify-content: flex-end;
  padding: 5px 15px;
  position: absolute;
  bottom: 0;
  flex: 1 1 100%;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 0px 1px #efefef;
  margin: 1px;
  z-index: 2;
}

.canvas-footer button {
  width: 100px;
  padding: 0px;
}

.canvas-footer .btn-secondary {
  --bs-btn-color: var(--dk-blue);
  --bs-btn-bg: ##f5f5f5;
  --bs-btn-border-color: var(--dk-blue);
}

.gpnt-canvas-body {
  padding: 0 0 70px 0 !important;
  background: #fcfcfc;
}

.gpnt-canvas-body .gpnt-card {
  background: transparent;
}

.gpnt-canvas-body .gpnt-card .card-body {
  background: transparent;
  /* box-shadow: 0px 0px 0px 1px #efefef; */
  padding: 10px 15px;
}

.gpnt-grid-box {
  padding-top: 0.8em;
  position: relative;
  padding-bottom: 0.8em;
}

.gpnt-toolbar-header {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}
[classname="gpnt-toolbar-header"] {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 3px;
}

.gpnt-grid-box .dx-datagrid-header-panel,
.gpnt-grid-box .dx-datagrid-header-panel .dx-toolbar {
  padding-bottom: 3px;
  background-color: var(--web-bg);
}

.dx-datagrid-headers td {
  font-weight: 500;
  color: #555;
}

.dx-header-filter {
  font-size: 9px !important;
}

.gpnt-grid-box .dx-toolbar-item-content .dx-button-has-icon .dx-icon {
  width: 22px;
  height: 16px;
  font-size: 16px;
}

.gpnt-grid-box .dx-toolbar-item-content .dx-texteditor-input {
  min-height: 26px;
}

.error-message {
  color: #bc2121;
  font-size: 11px;
}

.field-error {
  border: 1px solid #bc2121 !important;
}

.img-preview {
  min-height: 90px;
  max-height: 90px;
  max-width: 100%;
  text-align: center;
}

.img-preview img {
  max-height: 90px;
  max-width: 100%;
  object-fit: contain;
}

.req-star {
  color: #f44336;
  font-size: 14px;
  padding-left: 5px;
}

.bg-add-form {
  background: #c0ffc0;
}

.bg-update-form {
  background: #ffc0c0;
}

.bg-pay-in {
  background: #92ff92;
}

.bg-pay-out {
  background: #ff8c8c;
}

.route-loader {
  position: fixed;
  height: 100%;
  width: 100%;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  max-width: 100px;
  max-height: 100px;
  z-index: 5;
}

.sidebar-visible .route-loader {
  left: calc(50% + 75px);
}

.dx-row-focused .gpnt-grid-edit {
  /* color: #fff !important; */
}

.gpnt-grid-edit,
.gpnt-grid-delete {
  font-size: 13px;
  cursor: pointer;
}

.dx-row-focused-payout,
.dx-row-focused-payout td {
  background-color: rgb(255, 71, 102) !important;
}

.dx-row-focused-payin,
.dx-row-focused-payin td {
  background-color: rgb(0, 255, 0) !important;
}

.dx-cell-expired {
  background-color: #ff0000 !important;
}

.dx-row-focused-inactive,
.dx-row-focused-inactive td {
  background-color: #f5b5c0 !important;
}

.dx-datagrid .dx-row > td {
  height: 28px !important;
}

.gpnt-modal-header {
  background-color: #fff;
}

.max-w-40 {
  max-width: 40px;
}

.max-w-50 {
  max-width: 50px;
}

.max-w-55 {
  max-width: 55px;
}

.max-w-65 {
  max-width: 65px;
}

.max-w-75 {
  max-width: 75px;
}

.max-w-80 {
  max-width: 80px;
}

.max-w-85 {
  max-width: 85px;
}

.max-w-90 {
  max-width: 90px;
}

.max-w-95 {
  max-width: 95px;
}

.max-w-100 {
  max-width: 100px;
}

.max-w-110 {
  max-width: 110px;
}

.max-w-115 {
  max-width: 115px;
}

.max-w-120 {
  max-width: 124px;
}

.max-w-124 {
  max-width: 124px;
}

.max-w-130 {
  max-width: 130px;
}

.max-w-134 {
  max-width: 134px;
}

.max-w-140 {
  max-width: 140px;
}

.max-w-150 {
  max-width: 150px;
}

.max-w-160 {
  max-width: 160px;
}

.max-w-175 {
  max-width: 175px;
}
.max-w-200 {
  max-width: 200px;
}
.max-w-220 {
  max-width: 220px;
}
.max-w-248 {
  max-width: 248px;
}

.max-w-310 {
  max-width: 310px;
}
.max-w-440 {
  max-width: 440px;
}

.w-205 {
  max-width: 205px !important;
  min-width: 205px !important;
}
.min-w-80 {
  min-width: 80px;
}
.max-w-1320 {
  max-width: 1320px;
}
.h-30 {
  height: 30px;
}

.gpnt-switch-row .gpnt-form-label {
  padding-top: 2px !important;
}

.label-find-address {
  font-size: 16px;
}

.bg-grey {
  background: #ececec;
}

.gpnt-form-footer {
  background: #eee;
  box-shadow: 0px 0px 0px 1px #efefef;
  padding-left: 11px;
  border: 1px solid #ddd3d3;
  text-align: right;
}

.page-form-box {
  padding-top: 0.7rem;
}

.text-info {
  color: #333 !important;
  font-weight: 500;
}

.text-left {
  text-align: left !important;
}

.dx-context-menu .dx-submenu .dx-menu-items-container .dx-menu-item-wrapper {
  border-bottom: 1px solid #e4e4e4 !important;
}

.dx-context-menu
  .dx-submenu
  .dx-menu-items-container
  .dx-menu-item-wrapper:last-of-type {
  border-bottom: 0px solid #e4e4e4 !important;
}

.dx-context-menu
  .dx-submenu
  .dx-menu-items-container
  .dx-menu-item-wrapper
  .dx-menu-item-text {
  color: #000;
  font-weight: 600;
}

.clear-both {
  clear: both;
}

.dx-group-cell {
  color: #000 !important;
  background: #d2d2d2;
}

.toolbar-select {
  position: relative;
  left: 10px;
  bottom: 3px;
  font-size: 12px;
  width: 175px;
  max-width: 175px;
}

.cursor-pointer {
  cursor: pointer;
}

.fw-600 {
  font-weight: 600;
}

.gpnt-role-box {
  /* max-width: 20% !important; */
  /* min-width: 252px !important; */
  margin: 0 0 0 0;
  padding: 5px;
}

.gpnt-checkbox-tree .react-checkbox-tree {
  padding: 0;
}

.gpnt-checkbox-tree .rct-title {
  padding: 0 5px;
  font-size: 0.8rem;
  text-transform: capitalize;
  /* font-weight: 500; */
  position: relative;
  bottom: 1px;
}

.gpnt-checkbox-tree .rct-node-icon {
  display: none;
}

.gpnt-checkbox-tree .rct-collapse,
.gpnt-checkbox-tree .rct-checkbox,
.gpnt-checkbox-tree .rct-node-icon {
  padding: 0;
}

.gpnt-checkbox-tree .card-body {
  padding: 8px 5px;
}

.gpnt-checkbox-tree .card-header {
  padding: 5px 18px;
}

.gpnt-role-info-body {
  padding: 0px 8px !important;
}

.gpnt-checkbox-tree .rct-node-parent .rct-icon-expand-close,
.gpnt-checkbox-tree .rct-node-parent .rct-icon-expand-open {
  padding-right: 10px;
}

.gpnt-checkbox-tree .react-checkbox-tree ol ol {
  display: flex;
}

.business-checklist .react-checkbox-tree {
  background: #ffffff;
  padding: 8px 0px;
}

.headoffice-set-box {
  position: relative;
  padding-bottom: 5rem;
}

.gpnt-pos-param-header {
  padding: 5px 8px;
  font-weight: 600;
  font-size: 0.9rem;
}

.gpnt-pos-param-body {
  padding: 10px 0px 0 10px;
}

.headoffice-set-box .gpnt-form-label,
.headoffice-set-box .gpnt-form-control {
  font-size: 0.76rem;
  max-width: 95%;
}

.notification-count {
  position: absolute;
  right: 5px;
  bottom: 14px;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  line-height: 18px;
  text-align: center;
  padding: 0;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.8px;
}

.notification-header {
  background-color: rgb(0 124 183);
  color: #fff;
  padding: 5px;
  text-transform: uppercase;
  font-size: 11px;
  text-align: left;
  font-weight: 600;
}

.notification-item {
  padding: 2px 5px 2px 5px;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
  background: #fdfdfd;
}

.chk-approval {
  margin-left: 10px;
  font-weight: 700;
  color: var(--dk-blue);
  position: relative;
}

.chk-approval .form-check-input {
  height: 18px;
  width: 18px;
}

.chk-approval .form-check-label {
  line-height: 23px;
  padding-left: 5px;
  font-size: 11px;
  text-transform: uppercase;
}

.page-pending-box {
  position: relative;
  max-width: 206px;
  display: flex;
}

.page-pending-count {
  font-weight: 700;
  width: 20px;
  height: 17px;
  text-align: center;
  border-radius: 4px;
  line-height: 23px;
  color: #d43f3a;
}

.page-pending-count::before {
  content: "(";
}

.page-pending-count::after {
  content: ")";
}

.page-pending-box::before {
  content: "";
  width: 0;
  height: 20px;
  position: absolute;
  border: 1px solid #f2f0f0;
  top: 2px;
  left: 2px;
}

.h-line {
  height: 2px;
  background: #eee;
  width: 70px;
  position: absolute;
  left: -24px;
}

.h-line-word {
  position: absolute;
  top: -9px;
  width: 25px;
  height: 25px;
  left: calc(50% - 12.5px);
  font-size: 12px;
  background: #fff;
  text-align: center;
}

.gpnt-form-checkbox .form-check-label {
  position: relative;
  top: 1px;
}

.gpnt-promo-info-body {
  padding: 8px;
}

.gpnt-table thead th {
  padding: 6px 8px 6px 8px !important;
  font-size: 12px;
  color: var(--dk-blue);
}

.gpnt-table tbody tr td {
  padding: 2px 8px 2px 8px !important;
  font-size: 14px;
}

.gpnt-table tbody tr td:first-of-type .form-check {
  margin: 0;
  padding: 0;
  min-height: auto;
}

.gpnt-table tbody tr td:first-of-type .form-check input {
  margin-left: 0px;
}

.gpnt-edit-table tbody tr td {
  padding: 0 !important;
  vertical-align: middle;
}

.gpnt-edit-table tbody tr td input[type="text"] {
  padding: 0px 8px 0px 8px !important;
  font-size: 14px;
}

.gpnt-edit-table tbody tr td .input-tbl-cell {
  width: 100%;
  border: 0;
  background: #fdfdfd;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 0px 5px;
  line-height: 28px;
  height: 28px;
  outline: none;
  font-size: 13px;
  font-weight: 500;
  color: #333;
}

.card-header {
  padding: 5px;
  font-size: 14px;
}

.promotion-box .rct-collapse,
.promotion-box .rct-node-icon {
  display: none;
}

.has-diff-headcolor .dx-datagrid .dx-datagrid-headers .dx-header-row > td {
  color: var(--dk-blue);
  font-size: 12px;
}

.promotion-box .prom-box-pad {
  padding-left: 0px;
}

.chk-weekdays {
  display: inline;
}

.btn-datagrid-primary .dx-button,
.btn-datagrid-primary .dx-button .dx-state-hover,
.btn-datagrid-primary .dx-button .dx-state-hover {
  background: var(--dk-blue) !important;
  color: #fff;
}

.btn-datagrid-primary .dx-button i,
.btn-datagrid-danger .dx-button i {
  color: #fff;
}

.btn-datagrid-danger .dx-button {
  background: #dc3545 !important;
  background: var(--dk-blue);
  color: #fff;
}

.grid-inside-popup .dx-datagrid-header-panel {
  margin-bottom: 4px;
}

.modal-dialog-centered .modal-content {
  border-radius: 8px;
}

.promo-dt-lifetime-btn {
  font-size: 10px;
  padding: 0 5px;
  height: 28px;
  font-weight: 600;
  width: 100%;
  line-height: 1.2;
  text-transform: uppercase;
  margin-top: 32px;
  --bs-btn-color: var(--dk-blue);
  --bs-btn-bg: ##f5f5f5;
  --bs-btn-border-color: var(--dk-blue);
}

.promo-set24h-btn {
  font-size: 12px;
  padding: 0;
  height: 22px;
  font-weight: 600;
  width: 100%;
  line-height: 1;
  text-transform: uppercase;
  margin: auto;
  margin-top: 10px;
  border-radius: 4px;
  max-width: 120px;
}

.prod-input-group {
  font-size: 12px;
  padding: 0 8px;
  font-weight: 600;
}

.w-85 {
  width: 85px;
}

.gpnt-promo-info-body .rct-checkbox {
  padding-right: 0;
}

.gpnt-promo-info-body .rct-title {
  font-size: 0.8rem;
  font-weight: 500;
}

.form-check:not(.form-switch) .form-check-input:checked[type="checkbox"] {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggZmlsbD0nbm9uZScgc3Ryb2tlPScjMzM3YWI3JyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMycgZD0nbTYgMTAgMyAzIDYtNicvPjwvc3ZnPg==);
  background-color: #fff;
  border-color: #0d6efd;
}

.hide-datagrid-checkall .dx-header-row .dx-checkbox-icon {
  display: none;
}

.hide-datagrid-checkall
  .dx-datagrid-rowsview
  .dx-row-focused:not(.dx-row-alt)
  > td {
  background-color: #5c95c5 !important;
}

.hide-datagrid-checkall
  .dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused, .dx-row-alt)
  > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-datagrid-rowsview
  .dx-selection.dx-row:not(.dx-row-focused):hover
  > tr
  > td {
  background-color: #fbfbfb !important;
  color: #333;
}

.hide-datagrid-checkall .dx-selection.dx-row-alt:not(.dx-row-focused) > td,
.hide-datagrid-checkall
  .dx-selection.dx-row-alt:not(.dx-row-focused):hover
  > td {
  background-color: #fefefe !important;
}

.gpnt-alert-header,
.gpnt-alert-body,
.gpnt-alert-footer {
  padding: 8px 10px;
}

.gpnt-alert-title {
  font-weight: 600;
  color: #000 !important;
}

.gpnt-alert-icon {
  display: block;
  text-align: center;
  margin: auto;
  font-size: 55px;
  color: #faa429;
  margin-top: 10px;
}

.gpnt-alert-msg {
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0px;
}

.btn-card-footer {
  /* border-radius: 0 !important; */
  height: 35px;
  font-size: 14px;
  padding: 0 15px;
  /* font-weight: 500; */
}

/* .card, */
.dx-button,
.dx-texteditor-input,
.dx-texteditor.dx-editor-outlined {
  border-radius: 0 !important;
}

.gpnt-grid-box .dx-datagrid .dx-datagrid-headers .dx-header-row > td {
  background: #dff5ff2b;
  font-weight: 700;
}

.gpnt-grid-box .dx-datagrid-filter-panel {
  background: #dff5ff2b;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.dx-datagrid-group-closed,
.dx-datagrid-group-opened {
  font: 26px/18px DXIcons !important;
  height: 21px !important;
}

.dx-group-row td {
  color: #333 !important;
  background: #006ac41c;
}

.group-row-icon {
  position: relative;
  bottom: 2px;
  margin-right: 8px;
  left: 0;
  color: #959393;
}

.dx-group-row.dx-row-focused td {
  /* color: #fff !important; */
}

.group-row-label {
  font-weight: 500;
}

.dx-group-row.dx-row-focused td .group-row-icon {
  /* color: #fff !important; */
}

.dx-datagrid-rowsview .dx-master-detail-row > .dx-master-detail-cell {
  padding: 5px !important;
}

.gpnt-grid-box .dx-datagrid-borders > .dx-datagrid-pager {
  padding: 15px 15px 10px 15px;
  margin: 0 0 10px 0;
}

.prices-table tbody tr td input {
  height: 28px;
}

.price-loading {
  min-width: 124px;
  height: 29.6px;
  line-height: 29.6px;
}

.btn,
.header-select,
.input-group-text,
.gpnt-form-control,
.gpnt-form-checkbox input[type="checkbox"],
.form-control {
  /* border-radius: 0 !important; */
}

.grid-custom-search {
  max-width: 278px;
  position: absolute;
  right: 284px;
  top: 13px;
  min-width: 214px;
}

.grid-custom-search input {
  z-index: 1;
}

.select__control {
  min-height: 28px;
  padding: 0px 5px;
  font-size: 13px;
  border-radius: 3px;
  font-weight: 500;
  color: #333;
  min-height: 28px !important;
}

.select__indicator-separator {
  display: none;
}

.select__indicator {
  position: absolute;
  right: 10px;
  bottom: 3px;
  width: 20px;
}

.select__value-container {
  padding: 0 0px !important;
  min-height: 28px;
}

.select__multi-value {
  min-height: 20px;
  line-height: 1.5;
  margin: 0 5px 0 0 !important;
  position: relative;
  bottom: 0px;
  background-color: var(--dk-blue) !important;
  color: #fff;
}

.select__multi-value__label {
  padding: 3px 5px !important;
  font-size: 11px !important;
  letter-spacing: 0.5px;
  color: #fff !important;
}

.select__input-container {
  margin: 0 !important;
  min-height: 28px;
  padding: 0 !important;
}

.select__placeholder {
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 0 !important;
  margin: 0 !important;
  color: #333 !important;
}

.tabview-close {
  position: relative;
  right: 0;
  font-size: 18px;
}
.hidden-number {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}
.react-select-main .css-13cymwt-control,
.react-select-main .css-t3ipsp-control,
.react-select-main .css-16xfy0z-control {
  min-height: 28px;
  height: 28px;
  line-height: 14px;
}
.react-select-main.multiple-select .css-13cymwt-control,
.react-select-main .css-t3ipsp-control,
.react-select-main .css-16xfy0z-control {
  min-height: 28px;
}
.react-select-main .css-1fdsijx-ValueContainer {
  padding: 0 4px;
}
.react-select-main .css-1xc3v61-indicatorContainer,
.react-select-main .css-15lsz6c-indicatorContainer {
  padding: 0;
}
.btn-gpnt-input-group {
  height: 28px;
  line-height: 10px;
}
.btn-gpnt-input-group .text {
  font-size: 12px;
  position: relative;
  top: 1px;
}
.clearfix {
  clear: both;
}
.readonly {
  background: #eee;
  pointer-events: none;
}
@media only screen and (max-width: 1024px) {
  .i-del-day {
    left: 10px !important;
    top: -5px !important;
    z-index: 1;
  }
  .promotion-box .prom-box-pad {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: 8px;
  }

  .gpnt-role-box {
    /* min-width: 100% !important; */
  }

  .has-custom-toolbar .dx-datagrid-header-panel {
    height: auto;
    min-height: 40px;
  }
  .has-custom-toolbar .dx-toolbar .dx-toolbar-items-container {
    align-items: start !important;
    flex-direction: column;
  }
  /* .has-custom-toolbar .dx-toolbar-before,.has-custom-toolbar .dx-toolbar-after, .dx-toolbar-before, .dx-toolbar-center {
    display: flex;
    justify-content: end;
    width: 100%;
  } */
  /* .has-custom-toolbar .dx-toolbar-before {
      display: flex;
      flex-direction: column;
      width: auto!important;
      
    } */
  .has-custom-toolbar .dx-toolbar-after,
  .has-custom-toolbar .dx-toolbar-after .dx-toolbar-before {
    position: absolute !important;
  }
  .has-custom-toolbar .custom-toolbar {
    left: 0;
    bottom: 0;
    top: 2px;
  }

  .chk-approval {
    margin-left: 0;
  }

  .page-pending-box::before {
    border: 0;
  }
}
@media only screen and (max-width: 768px) {
  .has-custom-search .dx-datagrid-header-panel {
    height: 85px;
  }
  .grid-custom-search {
    max-width: 278px;
    position: absolute;
    right: 0;
    top: 55px;
    width: 100%;
  }

}
@media only screen and (max-width: 414px) {
  .has-custom-search .dx-datagrid-header-panel {
    height: 95px;
  }
  .grid-custom-search {
    max-width: 278px;
    position: absolute;
    right: 0;
    top: 65px;
    width: 100%;
  }
  
}

@media only screen and (max-width: 575px) {
  .card-header-button {
    width: 50% !important;
  }
  .card-header-text {
    width: 50% !important;
  }
  .rating {
    width: 60%;
  }
}

.dropdown-index {
  z-index: 6 !important;
}

.bar-set-box {
  padding-bottom: 5rem;
  position: relative;
}

.uploadinnerdiv {
  display: grid;
  align-items: center;
  height: 300px;
  border: 2px dashed rgb(167, 167, 167);
}

.fileinput {
  display: none;
}
.uploadicon {
  font-size: 40px;
}
.editicon {
  font-size: 20px;
  margin-right: 5px;
}

.previewimg {
  max-width: 100%;
  /* width: 100%;
    height: 400px; */
}

.radiobtn1 {
  border-radius: 5px 0 0 5px;
}

.radiobtn2 {
  border-radius: 0 5px 5px 0;
}

.quantitygroup {
  height: 35px !important;
}

/* .rating {
  display: inline-block;
  position: relative;
  height: 50px;
  font-size: 28px;
}

.rating label {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
}

.rating label:last-child {
  position: static;
}

.rating label:nth-child(1) {
  z-index: 5;
}

.rating label:nth-child(2) {
  z-index: 4;
}

.rating label:nth-child(3) {
  z-index: 3;
}

.rating label:nth-child(4) {
  z-index: 2;
}

.rating label:nth-child(5) {
  z-index: 1;
}

.rating label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.rating label .icon {
  float: left;
  color: transparent;
}

.rating label:last-child .icon {
  color: #000;
}

.rating:not(:hover) label input:checked ~ .icon,
.rating:hover label:hover input ~ .icon {
  color: red !important;
}
.rating {
  display: inline-block;
  position: relative;
  height: 50px;
  font-size: 28px;
}

.rating label {
  position: relative;
  cursor: pointer;
}

.rating label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.rating label .icon {
  color: transparent;
}

.rating label:last-child .icon {
  color: #000;
}

.rating:not(:hover) label input:checked ~ .icon,
.rating:hover label:hover input ~ .icon {
  color: red !important;
}

.rating label input:focus:not(:checked) ~ .icon:last-child {
  color: #000 !important;
  text-shadow: 0 0 5px red !important;
}

.rating label input:focus:not(:checked) ~ .icon:last-child {
  color: #000 !important;
  text-shadow: 0 0 5px red !important;
} */

.nameinputheight {
  height: 35px;
}
.descriptionheight {
  height: 100px;
}
.css-b62m3t-container .css-1nmdiq5-menu {
  z-index: 6;
}

.previewimg {
  height: 100%;
  width: 100%;
  max-height: 100%;
  object-fit: fill;
}

.setcardfix {
  height: fit-content;
}

.h-75px {
  height: 75px;
}
.z-6 {
  z-index: 6;
}

/* 
 
fieldset.star-rating {
  border: none;
  padding: 0;
  margin: 0;
  display: inline-grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-columns: 1fr;
  width: fit-content;
}
fieldset.star-rating label {
  cursor: pointer;
  text-align: center;
}
fieldset.star-rating input {
  appearance: none;
  background-color: transparent;
  cursor: pointer;
  grid-row: 2;
  height: auto;
  margin: 0;
  padding: 0.2rem;
  text-align: center;
  transition: transform 0.2s ease-out;
  width: auto;
}
fieldset.star-rating input:after {
  color: black;
  content: "\1F336";
  font-size: 25px;
}
fieldset.star-rating input:hover:after {
  color: red;
  content: "\1F336";
}
fieldset.star-rating input:checked:after {
  color: red;
  content: "\1F336";
}
fieldset.star-rating input:has(~ input:hover):after, fieldset.star-rating input:has(~ input:checked):after, fieldset.star-rating input:has(~ input:focus):after {
  color: red;
  content: "\1F336";
}
fieldset.star-rating input:hover ~ input:after {
  color: black;
  content: "\1F336";
}
@keyframes star-rating-active-anim {
  from {
    color: black;
  }
  to {
    color: red;
  }
}
fieldset.star-rating fieldset.star-rating.hidelabels label {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
fieldset.star-rating fieldset.star-rating:disabled label, fieldset.star-rating fieldset.star-rating:disabled input {
  pointer-events: none;
}
fieldset.star-rating fieldset.star-rating:disabled label, fieldset.star-rating fieldset.star-rating:disabled input:after, fieldset.star-rating fieldset.star-rating:disabled input:has(~ input:hover):after, fieldset.star-rating fieldset.star-rating:disabled input:has(~ input:checked):after, fieldset.star-rating fieldset.star-rating:disabled input:has(~ input:focus):after {
  color: red;
} */


@media only screen and (max-width: 426px) {
  .gallarygap{
    gap: 15px;
  }
}