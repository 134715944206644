/* .header-tab-menus{
    background: #fff;
    color: var(--dk-blue);
}
.header-tab-menus .item{
    color: #333;
    text-transform: capitalize;
    font-size: 12px;
}
.header-tab-menus .item.active{
    color: #333;
    background-color: #e6e6e6;
    border-top:  0;
    border-bottom: 3px solid var(--dk-blue);
    font-weight: 600;
} */

.row {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }
  
  .btn-copy-price {
    border-radius: 0;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin: 8px 0 0 0;
    padding: 2px 5px;
    max-width: 240px;
  }
  
  .header-tab-menus .item {
    text-transform: capitalize;
    font-size: 12px;
  }
  
  .header-tab-menus .item.has-submenu.active {
    padding-right: 24px;
    border-bottom: 0 !important;
  }
  
  .header-tab-menus .item.has-submenu.active::after {
    content: "";
    position: absolute;
    right: 5px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #3f51b5;
    top: 12px;
  }
  
  .header-tab-submenus {
    overflow: auto;
    white-space: nowrap;
    border-bottom: 1px solid #cccfd1;
    box-shadow: 1px 2px 2px #efefef;
    position: relative;
    padding-left: 0px;
    clear: both;
  }
  
  .header-tab-submenus .subemenu-arrow {
    color: var(--dk-blue);
  }
  
  .header-tab-submenus .item {
    padding: 5px 0px;
    font-weight: 500;
    color: #333;
    font-size: 10px;
    cursor: pointer;
    text-decoration: none;
    margin: 0 10px;
    border-radius: 0;
    text-transform: uppercase;
    letter-spacing: 0.3px;
  }

  .h-40{
    height: 49px;
  }

  .h-243{
    height: 243px;
  }

  .h-210{
    height: 210px;
  }

  .h-290{
    height: 290px;
  }

 .fs-icon{
  font-size: 18px;
 }
  .pe-auto{
    cursor: pointer;
  }
  
  .header-tab-submenus .item.active {
    border: 1px solid transparent;
    border-radius: 0;
    border-bottom: 1px solid var(--dk-blue);
    font-weight: 700;
    color: var(--dk-blue);
  }
  
  .successful-message {
    color: #a7dfc6;
  }
  
  .menu-tabs .nav-item {
    border: 0;
  }
  
  .menu-tabs .nav-link {
    border: 0;
    border-bottom: 1px solid #eee;
    color: #000;
    padding-top: 0;
    font-size: 14px;
  }
  
  .menu-tabs .nav-link.active {
    border: 0;
    border-bottom: 2px solid #333;
    color: #000;
    font-weight: 700;
    letter-spacing: 0.3px;
  }
  
  .menu-tabs-tab-title {
    text-align: left;
    padding-left: 0;
    font-weight: 600;
    font-size: 16px;
    color: var(--dk-blue);
    border: 0;
    padding: 5px;
  }
  
  .menu-type-items-title {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    color: #333;
    border: 0;
    padding: 5px;
  }
  
  .gpnt-poupup-tabs {
    display: flex;
    justify-content: start;
    background: var(--dk-blue);
    position: relative;
  }
  
  .gpnt-poupup-tabs .nav-item,
  .gpnt-poupup-tabs .nav-item:focus-visible {
    outline: none;
  }
  
  .gpnt-poupup-tabs .nav-item:first-of-type {
    min-width: 80px;
    position: relative;
  }
  
  .gpnt-poupup-tabs .nav-item:first-of-type:after,
  .gpnt-poupup-tabs .nav-item:first-of-type:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  
  .gpnt-poupup-tabs .nav-item:first-of-type:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #fff;
    border-width: 0px;
    margin-top: -10px;
  }
  
  .gpnt-poupup-tabs .nav-item:first-of-type:before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: #fff;
    border-width: 8px;
    margin-top: -7px;
  }
  
  .gpnt-poupup-tabs .nav-item:last-of-type {
    position: absolute;
    right: 0;
    background: transparent;
  }
  
  .gpnt-poupup-tabs :nth-child(2) {
    background: var(--dk-blue);
    width: 25px;
    border: 0;
    border-color: transparent;
  }
  
  .gpnt-poupup-tabs .nav-item:last-of-type {
    background: #d43f3a;
  }
  
  .gpnt-poupup-tabs .nav-item:first-of-type .nav-link.disabled {
    background: transparent;
    border: 0;
    color: #ffffff !important;
    font-weight: 700;
    font-size: 17px;
    letter-spacing: -0.5px;
  }
  
  .gpnt-poupup-tabs .nav-item .nav-link {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
    color: #fff !important;
    transition: none !important;
    margin-bottom: 0 !important;
  }
  
  .gpnt-poupup-tabs .nav-item .nav-link.active {
    color: var(--dk-blue) !important;
    border-color: transparent;
  }
  
  .gpnt-poupup-tabs .nav-link:hover,
  .gpnt-poupup-tabs .nav-link:focus {
    isolation: unset !important;
    border-color: transparent !important;
    background: #eee;
    color: #000 !important;
  }
  
  .gpnt-poupup-tabs .nav-item:last-of-type .nav-link:hover,
  .gpnt-poupup-tabs .nav-item:last-of-type .nav-link:focus {
    isolation: unset !important;
    border-color: transparent !important;
    background: transparent !important;
    color: #fff !important;
  }
  
  .promotion-box .tab-content {
    padding: 0 0.5rem !important;
  }
  
  .gpnt-grid-edit-warning {
    color: #fb8700f5 !important;
  }
  
  .hdr-pending-approval {
    color: #fb8700f5 !important;
    font-weight: 700;
    background: #fff;
    text-align: left;
    text-transform: uppercase;
    padding: 8px;
    font-size: 14px;
  }
  
  .grid-custom-search-btn {
    height: 28px;
    line-height: 15px;
  }
  
  .mb-12x {
    margin-bottom: 12px;
  }
  
  #controlled-tab-product-tab-2.nav-link.nav-link.disabled,
  #controlled-tab-product-tab-3.nav-link.nav-link.disabled,
  #controlled-tab-product-tab-4.nav-link.nav-link.disabled,
  #controlled-tab-product-tab-5.nav-link.nav-link.disabled,
  #controlled-tab-product-tab-6.nav-link.nav-link.disabled {
    display: none;
  }
  
  .product-box #controlled-tab-product-tabpane-0,
  .product-box #controlled-tab-product-tabpane-1,
  .product-box #controlled-tab-product-tabpane-2,
  .product-box #controlled-tab-product-tabpane-3,
  .product-box #controlled-tab-product-tabpane-4,
  .product-box #controlled-tab-product-tabpane-5 {
    padding: 0 0.5rem !important;
  }
  
  #controlled-tab-stock-return-supplier-tab-1.nav-link.nav-link.disabled {
    display: none;
  }
  
  .highlight-label-red .form-check-label {
    color: red;
    font-weight: 600;
  }
  
  .maxwidth-dialog {
    max-width: 1200px;
  }
  
  .chk-group-icon {
    position: absolute;
    left: -4px;
    font-size: 21px;
    bottom: 1px;
    color: #44444470;
  }
  
  .chk-group-icon-sd {
    position: absolute;
    left: -4px;
    font-size: 21px;
    bottom: 4px;
    color: #44444470;
  }
  
  .chk-group-items {
    padding-left: 2.4rem;
  }
  
  .show-toggle-div {
    display: none;
  }
  
  .show-toggle-div.visible {
    display: block;
  }
  
  .staff-discount-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .sd-dep-name-label {
    width: 200px;
    margin-right: 30px;
  }
  
  .sd-dep-dicount {
    width: 90px;
  }
  
  .sd-dep-dicount::after {
    content: "%";
  }
  
  .sd-subdep-name-label {
    width: 200px;
    margin-right: 12px;
    font-size: 11px;
  }
  
  .sd-perc {
    margin-left: 5px;
    font-size: 12px;
    font-weight: 700;
    margin-right: 5px;
  }
  
  .grid-back-button .dx-button {
    background: transparent;
    position: relative;
    bottom: 7px;
    border-radius: 100% !important;
    border-color: var(--dk-blue) !important;
  }
  
  .grid-back-button .dx-button-has-icon .dx-icon {
    width: 12px !important;
    height: 12px !important;
    color: var(--dk-blue) !important;
    font-size: 17px !important;
  }
  
  .breadcrumb-item {
    font-size: 17px;
  }
  
  .validation-summary {
    padding: 8px;
    background: #eee;
  }
  
  .validation-summary p {
    margin-bottom: 0;
    color: red;
    font-size: 12px;
    font-weight: 600;
  }
  
  .validation-summary p::before {
    content: "\f111";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 8px;
    color: #ccc;
    font-size: 8px;
  }
  
  .pd-brand-dd {
    width: calc(100% - 68px) !important;
  }
  
  .qty-info-icon {
    position: absolute;
    right: -20px;
    bottom: 5px;
    cursor: pointer;
    color: #2196f3;
  }
  
  .form-text-info {
    font-size: 11px;
    display: block;
    margin-bottom: 5px;
    color: #5f5f5f;
  }
  
  .gpnt-sep-add {
    margin: 8px 0 !important;
    border: 1px solid #d2d2d2;
  }
  
  .btn-stock-count {
    width: auto !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .card-body-availability {
    background-color: #fff;
    padding: 0;
  }
  
  .lbl-avail-from {
    font-size: 12px;
    font-weight: 600;
    color: #337ab7;
  }
  
  .specific-day-check-label .form-check-label {
    font-size: 0.9rem;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  
  .accordion-icon {
    position: absolute;
    right: 5px;
    max-width: 40px;
    font-size: 22px;
    top: 0;
    color: #ccc;
  }
  
  .sd-add-new-i {
    position: relative;
    bottom: 2px;
  }
  
  .i-del-day {
    position: absolute;
    right: 0;
    top: calc(50% - 5px);
    z-index: 1;
  }
  
  .gpnt-switch-row-label .form-check-label {
    font-size: 0.8rem;
    text-align: left;
    text-transform: capitalize;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
    top: 2px;
  }
  
  .btn-add-day {
    font-size: 10px;
    background: #f1f1f1;
    color: var(--dk-blue);
    font-weight: 700;
  }
  .day-card {
    border-radius: 0 !important;
    border: 0 !important;
  }
  .day-card:nth-of-type(odd) {
    background-color: transparent;
  }
  
  .day-card:nth-of-type(even) {
    background-color: #e1e1ff;
  }
  .soh-ipt-grp-text {
    display: flex;
    font-size: 12px;
    font-weight: 500;
    justify-content: center;
    min-width: 40px;
    padding: 0 5px;
  }
  .btn-add-slot {
    font-size: 12px;
    height: 26px;
    line-height: 1;
    margin: 0;
    padding: 0;
    width: 26px;
  }
  .day-name {
    align-items: center;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: left;
    text-transform: capitalize;
    top: 2px;
  }
  .day-name,
  .gpnt-poupup-tabs {
    display: flex;
    position: relative;
  }
  .modal-max-width {
    max-width: none !important;
    width: 60%;
  }
  .gpnt-col-label {
    font-size: 0.8rem;
    text-align: left;
    text-transform: capitalize;
    font-weight: 600;
  }
  .gpnt-col-value {
    font-size: 0.8rem;
    text-align: left;
  }
  td.gpnt-col-value-list {
    padding: 0 !important;
  }
  .btn-compare-screen {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 4px 8px;
    line-height: 1;
  }
  .table-highlight-change {
    background-color: #ffc2c8 !important;
    color: #000;
    font-weight: 600;
  }
  .gpnt-col-value-list-div,
  .gpnt-col-value-list {
    background: #dcf9ff !important;
    padding: 0.5rem !important;
  }
  .table-col-info {
    --bs-table-bg: #f3f3f3;
    color: #000;
  }
  .gpnt-poupup-tabs .nav-link{
  padding: 0.5rem;
  }
  .css-olqui2-singleValue{
    color: #333!important;
  }
  .compare-grid-box .gpnt-col-value-list-div{
    max-width: 100%;
    overflow-y: auto;
  }
  .compare-grid-box .gpnt-col-value{
    width: 200px;
    max-width: 200px;
  }
  .compare-grid-box .table-col-info{
    width: 280px;
    max-width: 80px;
  }
  .compare-grid-box .table-col-acc-rej{
    width: 280px;
    max-width: 80px;
  }
  /* .has-custom-toolbar .dx-toolbar .dx-toolbar-items-container{
    height: auto!important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  } */
  /* .has-custom-toolbar .dx-toolbar .dx-toolbar-before{
    position: relative;
    width: calc(100% - 500px);
    display: flex;
  } */
  /* .has-custom-toolbar .dx-toolbar-after, .has-custom-toolbar .dx-toolbar-after .dx-toolbar-before{
    position: relative;
  }
  .has-custom-toolbar .dx-toolbar-after, .dx-toolbar-before, .dx-toolbar-center{
    display: flex;
  } */
  .react-select-main .css-1nmdiq5-menu{
    z-index: 3!important;
  }
  #productGrid .chk-approval{
    margin-left: 0;
  }
  .gpnt-advance-filter{
    background: #e3f2ff;
    box-shadow: 1px 2px 1px #e7e7e7;
    margin-bottom: 14px;
    padding: 0 8px;
  }
  .chk-second-supplier{
    font-size: 14px;
    font-weight: 500;
  }
  .highlight-grid-cell{
    background-color: red!important;
    color: #fff!important;
    font-weight: 600;
  }
  .form-control:disabled, .css-16xfy0z-control {
    background-color: #dddddd!important;
    opacity: 1;
  }
  .input-group-none{
    position: relative;
  }
  .inc-vat-info{
    height: 15px;
    border-radius: 0;
    font-size: 10px;
    font-weight: 600;
    padding-left: 3px;
    position: absolute;
    width: 100%;
    bottom: -14px;
    text-transform: uppercase;
    color: #337ab7
  }
  .inc-vat-info-profit{
    height: 15px;
    border-radius: 0;
    font-size: 10px;
    font-weight: 600;
    padding-left: 3px;
    text-transform: uppercase;
  }
  .input-group-text-btn{
    height: 28px;
    width: 66px;
    padding: 0;
    display: flex;
  }
  
  .input-group-text-btn i{
    font-size: 12px;
  }
  .input-group-text-btn span{
    font-size: 8px;
  }
  .btn-plus-minus-vat{
    padding: 3px 3px;
    font-size: 7px;
    font-weight: 600;
    border-radius: 0;
    height: 28px;
    border: 0;
  }
  .btn-plus-minus-vat.first{
    border-right: 1px solid #ddd;
  }
  .profit-inc-vat{
    background: transparent;
      border: 0;
      padding-left: 6px;
  }
  
    
  @media only screen and (max-width: 1400px) {
    .modal-max-width,.maxwidth-dialog {
      max-width: none !important;
      width: 100%;
    }
  }

  .pdf-view-restaurant{
    font-size: 13px;
    text-decoration: none;
    color: black;
    padding: 3px;
  }
  
  .max-w-100{
    max-width: 100% !important;
    width: 100% !important;
  }

  .delete-pdf-icon{
    cursor: pointer;
  }

  .w-260{
    width: 260px;
  }

  .header-select {
    line-height: 26px;
    height: 26px;
    padding: 0px 5px;
    margin: 8px 0;
    font-size: 12px;
}
